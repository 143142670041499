<template>
    <el-card>
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="全部" name="all">
                <el-table
                    ref="multipleTable"
                    :data="tableData"
                    tooltip-effect="dark"
                    style="width: 100%"
                    @selection-change="handleSelectionChange">
                    <el-table-column
                        type="selection">
                    </el-table-column>
                    <el-table-column
                        prop="address"
                        label="消息内容"
                        show-overflow-tooltip>
                        <template v-slot="scope">
                            <el-link :type="scope.row.status === 1 ? 'primary': 'info'" @click="show(scope.row)">
                                <i class="el-icon-chat-dot-square"></i>
                                {{ scope.row.address }}
                            </el-link>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="发送人"
                        width="120">
                    </el-table-column>
                    <el-table-column
                        label="接收时间"
                        width="120">
                        <template slot-scope="scope">{{ scope.row.date }}</template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="招聘信息" name="job">招聘信息</el-tab-pane>
            <el-tab-pane label="产品消息" name="product">产品消息</el-tab-pane>
            <el-tab-pane label="安全消息" name="safe">安全消息</el-tab-pane>
            <el-tab-pane label="财务消息" name="money">财务消息</el-tab-pane>
            <el-tab-pane label="寻物启示" name="find">寻物启示</el-tab-pane>
            <el-tab-pane label="其他消息" name="other">其他消息</el-tab-pane>
        </el-tabs>


        <el-dialog
            title="消息通知"
            :visible.sync="messageDialogVisible"
            width="50%">
            <span>这是一段信息</span>
        </el-dialog>

    </el-card>
</template>

<script>
export default {
    data() {
        return {
            // 默认显示消息
            activeName: 'all',
            // 消息列表
            tableData: [{
                date: '2016-05-03',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄',
                status: 1 // 默认未读
                }, {
                date: '2016-05-02',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄',
                status: 1
                }, {
                date: '2016-05-04',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄',
                status: 1
                }, {
                date: '2016-05-01',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄',
                status: 1
                }, {
                date: '2016-05-08',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄',
                status: 1
                }, {
                date: '2016-05-06',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄',
                status: 1
                }, {
                date: '2016-05-07',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄',
                status: 1
            }],
            multipleSelection: [],
            // 消息对话框
            messageDialogVisible: false
        }
    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        },
        toggleSelection(rows) {
            if (rows) {
            rows.forEach(row => {
                this.$refs.multipleTable.toggleRowSelection(row);
            });
            } else {
            this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        show(row) {
            this.messageDialogVisible = true
            row.status = 0 
            console.log(row)
        }
    }
}
</script>

<style lang="less" scoped>

</style>